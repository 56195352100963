import { Box, Table, TableBody, TableRow, Typography, Tooltip, Badge } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConditionalTooltip from '../../../components/tooltips/ConditionalTooltip';
import selectors from '../../../redux/reducers/conveyorTransactions/selectors';
import { getTableColorIndicatorV2 } from '../../../utils/tableColorIndicatorV2.ts';
import { applySortFilter } from '../../../utils/tableUtils';
import { TableCell, TableHead, TableMoreMenu } from '../table';
import TableMoreMenuItem from '../table/TableMoreMenu/TableMoreMenuItem';
import Iconify from '../../../components/Iconify';

const SIZE = 'xs';

export default function ConveyorTransactionsSubitems({
  list,
  loading,
  seeCost,
  handleOpenCorrectionModal,
  handleOpenCorrectionHistoryInfo,
  corrections,
}) {
  const { t } = useTranslation();
  const currentLanguage = useTranslation().i18n.language;
  const configs = useSelector(selectors.configs);
  const pageConfig = configs.map((config) => ({
    conveyorStepId: config.conveyorStepId,
    name: config.name,
    nameEs: config.nameEs,
    rules: config.rules,
    primaryTransactionType: config.primaryTransactionType,
  }));

  const order = 'asc';
  const orderBy = 'createdAt';

  const getTableHead = (t) => [
    { id: 'conveyorStep.name', label: t('table.conveyorStep'), alignRight: false },
    { id: 'corrections', label: t('table.corrections'), alignRight: false },
    { id: 'type', label: t('table.type'), alignRight: false },
    { id: 'quantity', label: t('table.quantity'), alignRight: true },
    { id: 'difference', label: t('table.difference'), alignRight: true },
    { id: 'differenceInPercent', label: t('table.differenceInPercent'), alignRight: true },
    ...(seeCost
      ? [
          { id: 'costPerUnit', label: t('label.costPerUnit'), alignRight: true },
          { id: 'costPerUnitDifferenceInPercent', label: t('table.costPerUnitDifferenceInPercent'), alignRight: true },
        ]
      : []),
    { id: 'room.name', label: t('table.factoryDepartment'), alignRight: false },
    { id: 'operationDuration', label: t('table.operationDuration'), alignRight: false },
    { id: 'createdBy', label: t('table.createdBy'), alignRight: false },
    { id: 'updatedAt', label: t('table.updatedAt'), alignRight: false },
    { id: 'createdAt', label: t('table.createdAt'), alignRight: false },
    { id: '' },
  ];

  const sortedTransactions = list.sort((a, b) => {
    const indexA = pageConfig.findIndex((config) => config.conveyorStepId === a.conveyorStep._id);
    const indexB = pageConfig.findIndex((config) => config.conveyorStepId === b.conveyorStep._id);

    return indexA - indexB;
  });

  function getTypeBackground(config, type) {
    if (config) {
      const rules = config.find((item) => item.rules)?.rules;

      if (rules?.transactionTypeRules) {
        const transactionTypes = rules.transactionTypeRules.find((item) => item?.indicators?.transactionType)
          ?.indicators?.transactionType;

        const typeColor = transactionTypes?.find((item) => item?.type === type);

        if (typeColor) {
          return typeColor.color;
        }
      }
    }

    return 'transparent';
  }

  const upgradedListWithColors = sortedTransactions.map((item) => {
    const indicatorForDifferencePercent = getTableColorIndicatorV2(
      pageConfig,
      item.conveyorStep._id,
      'differencePercent',
      Number(item.differenceInPercent)
    );

    const indicatorForType = getTypeBackground(pageConfig, item?.type);

    const operationDurationInMinutes = item.operationDuration / 60;

    const indicatorForOperationDuration = getTableColorIndicatorV2(
      pageConfig,
      item.conveyorStep._id,
      'duration',
      operationDurationInMinutes
    );

    return {
      ...item,
      typeIndicator: indicatorForType,
      differenceInPercentIndicator: indicatorForDifferencePercent,
      operationDurationIndicator: indicatorForOperationDuration,
    };
  });
  const getFakeComparator = (_order, _orderBy) => (_a, _b) => 0;
  const filteredItems = applySortFilter(upgradedListWithColors, getFakeComparator(order, orderBy));

  const getTooltipTitle = (item, indicator, currentLanguage) => {
    if (item === null) {
      return null;
    }

    return currentLanguage === 'en' ? indicator?.tooltip : indicator?.tooltipEs;
  };

  return (
    <Table>
      <TableHead
        isLoading={loading}
        size={SIZE}
        order={order}
        orderBy={orderBy}
        headLabel={getTableHead(t)}
        rowCount={list.length}
      />
      <TableBody>
        {filteredItems.map((item, index) => {
          const correctionsList = corrections.filter((correction) => correction?.tobaccoTransaction?._id === item._id);

          const tooltipText = `${correctionsList[correctionsList.length - 1]?.reasonType?.value}: ${
            correctionsList[correctionsList.length - 1]?.reasonType?.description
          }`;
          return (
            <TableRow size={SIZE} hover key={item?._id} tabIndex={-1}>
              <TableCell align="left" width={'200px'}>
                {item.conveyorStep?.name}
              </TableCell>
              <TableCell
                align="left"
                width={'30px'}
                sx={{ backgroundColor: correctionsList?.length > 0 ? 'lightgreen' : '' }}
              >
                {correctionsList && correctionsList?.length > 0 ? (
                  <Tooltip title={tooltipText}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="left"
                      onClick={() => handleOpenCorrectionHistoryInfo(correctionsList)}
                      sx={{ cursor: 'pointer', width: '100%' }}
                    >
                      <Iconify icon={'material-symbols:check'} height={20} width={20} color="green" />
                      <Badge color="primary" badgeContent={correctionsList?.length} sx={{ ml: 2 }} />
                    </Box>
                  </Tooltip>
                ) : null}
              </TableCell>
              <TableCell align="left" width={'100px'} sx={{ backgroundColor: item?.typeIndicator }}>
                {item.type}
              </TableCell>
              <TableCell align="right" width={'50px'}>
                {item.currentQuantity !== null ? item.currentQuantity : 'N/A'}
              </TableCell>
              <TableCell align="right" width={'20px'}>
                {(() => {
                  if (index === 0) return item.difference;
                  if (item.difference !== null) return item.difference;
                  return 'N/A';
                })()}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  backgroundColor: item.difference !== null ? item.differenceInPercentIndicator?.color : '',
                }}
              >
                <ConditionalTooltip
                  title={
                    item?.differenceInPercent &&
                    getTooltipTitle(item?.differenceInPercent, item.differenceInPercentIndicator, currentLanguage)
                  }
                >
                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    {(() => {
                      if (index === 0) return '';
                      return `${item.differenceInPercent} %`;
                    })()}
                  </Box>
                </ConditionalTooltip>
              </TableCell>
              {seeCost && (
                <TableCell align="right" width={'100px'}>
                  {item.costPerUnit}
                </TableCell>
              )}
              {seeCost && (
                <TableCell size={SIZE} align="right" style={{ whiteSpace: 'nowrap' }}>
                  {item.costPerUnitDifferenceInPercent ? (
                    <Typography
                      variant={'body3'}
                      color={item.costPerUnitDifferenceInPercent > 0 ? 'error.main' : 'success.main'}
                    >
                      {`(${item.costPerUnitDifferenceInPercent})`}
                    </Typography>
                  ) : (
                    ''
                  )}
                </TableCell>
              )}
              <TableCell align="left" width={'200px'}>
                {(() => {
                  if (item.factoryDepartment !== null) return item.factoryDepartment?.name;
                  return 'N/A';
                })()}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  backgroundColor: item.operationDurationIndicator?.color,
                }}
              >
                <ConditionalTooltip
                  title={
                    currentLanguage === 'en'
                      ? item?.operationDurationIndicator?.tooltip
                      : item?.operationDurationIndicator?.tooltipEs
                  }
                >
                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    {(() => {
                      if (index === 0) return item.durationFormatted;
                      if (item.durationFormatted !== null) return item.durationFormatted;
                      return 'N/A';
                    })()}
                  </Box>
                </ConditionalTooltip>
              </TableCell>
              <TableCell align="left">{item.createdBy}</TableCell>
              <TableCell align="left">{item.updatedAt !== null ? item.updatedAt : 'N/A'}</TableCell>
              <TableCell align="left">{item.createdAt !== null ? item.createdAt : 'N/A'}</TableCell>
              <TableCell align="right">
                <TableMoreMenu>
                  <TableMoreMenuItem icon="material-symbols:note" onClick={() => handleOpenCorrectionModal(item)}>
                    {t('label.correction')}
                  </TableMoreMenuItem>
                </TableMoreMenu>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

ConveyorTransactionsSubitems.propTypes = {
  list: PropTypes.array,
  corrections: PropTypes.array,
  loading: PropTypes.bool,
  seeCost: PropTypes.bool,
  handleOpenCorrectionModal: PropTypes.func,
  handleOpenCorrectionHistoryInfo: PropTypes.func,
};
