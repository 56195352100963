import { Card, Table, TableBody, TableContainer, TableFooter, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import selectors from '../../../redux/reducers/conveyorTransactions/selectors';
import { getTimeDifferenceWithDefaultEnd, getTimeDifferenceWithDefaultEndFormatted } from '../../../utils/formatTime';
import Scrollbar from '../../../components/Scrollbar';
import { dateLocales } from '../../../locales/i18n';
import SearchNotFound from '../../../components/SearchNotFound';
import { applySortFilter, getComparator } from '../../../utils/tableUtils';
import { ExtraSmallTableCell as TableCell, TableHead, TableToolbar } from '../table';
import ConveyorTransactionsRow from './ConveyorTransactionsRow';
import useAccess from '../../../hooks/useAccess';
import { getTobaccoLabel } from '../../../utils/modelLabels';

const SIZE = 'xs';

// ----------------------------------------------------------------------

const getTableHead = (t) => [
  { id: 'tobacco', label: t('label.tobacco'), alignRight: false },
  { id: 'transactions[0]?.qrId', label: t('table.qr'), alignRight: false },
  { id: 'table', alignRight: false },
];

// ----------------------------------------------------------------------

const getFilteredItem = (item) => {
  const { transactions } = item;
  const firstTransaction = transactions[0];
  const tobaccoCode = firstTransaction?.tobacco.code;
  return {
    tobacco: getTobaccoLabel(firstTransaction?.tobacco),
    flatCode: tobaccoCode?.replace(/_/g, ''),
    qrId: firstTransaction?.qrId,
    ...item.transactions?.reduce((acc, transaction) => {
      const {
        _id,
        type,
        quantity,
        createdBy,
        currentQuantity,
        costPerUnit,
        costPerUnitDifferenceInPercent,
        updatedAt,
        conveyorStep,
        difference,
        differenceInPercent,
        factoryDepartment,
        durationFormatted,
      } = transaction;

      return {
        ...acc,

        [`transaction_${_id}_type`]: type,
        [`transaction_${_id}_quantity`]: quantity,
        [`transaction_${_id}_createdBy`]: createdBy,
        [`transaction_${_id}_currentQuantity`]: currentQuantity,
        [`transaction_${_id}_costPerUnitDifferenceInPercent`]: costPerUnitDifferenceInPercent,
        [`transaction_${_id}_costPerUnit`]: costPerUnit,
        [`transaction_${_id}_conveyorStep`]: conveyorStep?.name,
        [`transaction_${_id}_updatedAt`]: updatedAt,
        [`transaction_${_id}_difference`]: difference,
        [`transaction_${_id}_differenceInPercent`]: differenceInPercent,
        [`transaction_${_id}_factoryDepartment`]: factoryDepartment?.name,
        [`transaction_${_id}_durationFormatted`]: durationFormatted,
      };
    }, {}),
  };
};

export default function ConveyorTransactionsTable({
  list = [],
  corrections = [],
  loading,
  handleOpenCorrectionModal,
  handleOpenCorrectionHistoryInfo,
}) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language || window.localStorage.i18nextLng || 'en';
  const locale = dateLocales[lang];
  const configs = useSelector(selectors.configs);
  const pageConfig = configs.map((config) => ({
    conveyorStepId: config.conveyorStepId,
    name: config.name,
    nameEs: config.nameEs,
    rules: config.rules,
    primaryTransactionType: config.primaryTransactionType,
  }));

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [filterName, setFilterName] = useState('');

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (value) => {
    setFilterName(value);
  };

  const listWithUpdatedTransactions = list.map((item) => {
    const updatedItem = { ...item, transactions: [...item.transactions] };
  
    pageConfig.forEach((config) => {
      if (!config?.rules?.stepIsRequired) return;
  
      if (config?.rules?.requiredIfPrevStepExistId) {
        const prevTransactionExists = item.transactions.some(
          (transaction) => transaction.conveyorStep._id === config.rules.requiredIfPrevStepExistId
        );
        if (!prevTransactionExists) return;
      }
  
      const transactionExists = updatedItem.transactions.some(
        (transaction) => transaction.conveyorStep._id === config.conveyorStepId
      );
  
      if (!transactionExists) {
        updatedItem.transactions.push({
          _id: uuidv4(),
          conveyorStep: { _id: config.conveyorStepId, name: config.name, nameEs: config.nameEs },
          type: config.primaryTransactionType,
          costPerUnit: null,
          currentQuantity: null,
          createdBy: null,
          updatedAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
          difference: null,
          differenceInPercent: null,
          factoryDepartment: null,
        });
      }
    });
  
    return updatedItem;
  });

  const upgradedList = listWithUpdatedTransactions?.map((item) => ({
    ...item,
    transactions: item?.transactions.map((transaction, index) => {
      const currentQuantity = transaction.quantity || 0;
      const previousQuantity = index > 0 ? item.transactions[index - 1].quantity || 0 : 0;

      const difference = index !== 0 ? (currentQuantity - previousQuantity).toFixed(2) : null;

      const differenceInPercent = (() => {
        if (index === 0) return null;
        if (previousQuantity === 0) return 0;
        return ((difference / previousQuantity) * 100).toFixed(2);
      })();

      const calculateOperationDuration = (previousDate, currentDate) => {
        const duration = getTimeDifferenceWithDefaultEnd(previousDate, currentDate);
        const durationTotalMinutes = duration?.minutes + duration?.hours * 60 + duration?.days * 24 * 60;
        const durationTotalSeconds = duration?.seconds + durationTotalMinutes * 60;

        return durationTotalSeconds;
      };

      const operationDuration = (() => {
        if (index === 0) return 0;
        const previousDate = item.transactions[index - 1].createdAt;
        const currentDate = transaction.createdAt;
        return calculateOperationDuration(previousDate, currentDate);
      })();

      const durationFormatted = (() => {
        if (!operationDuration) return null;
        const previousDate = item.transactions[index - 1].createdAt;
        const currentDate = transaction.createdAt;
        return getTimeDifferenceWithDefaultEndFormatted(previousDate, currentDate, locale);
      })();

      const costPerUnitDifferenceInPercent = (() => {
        if (index === 0) return null;
        const previousCostPerUnit = item.transactions[index - 1].costPerUnit;
        if (previousCostPerUnit === 0) return 0;
        return ((transaction.costPerUnit - previousCostPerUnit) / previousCostPerUnit) * 100;
      })();

      return {
        ...transaction,
        createdBy: transaction.createdBy === null ? 'N/A' : transaction.createdBy?.fullName,
        updatedAt: t('date.shortWithTime', { date: parseISO(transaction.updatedAt) }),
        createdAt: t('date.shortWithTime', { date: parseISO(transaction.createdAt) }),
        currentQuantity: currentQuantity?.toFixed(2),
        previousQuantity: previousQuantity?.toFixed(2),
        difference: transaction.difference === null ? 'N/A' : difference,
        differenceInPercent: transaction.differenceInPercent === null ? 'N/A' : differenceInPercent,
        operationDuration,
        durationFormatted,
        costPerUnit: transaction.costPerUnit === null ? 'N/A' : transaction.costPerUnit.toFixed(2),
        costPerUnitDifferenceInPercent: costPerUnitDifferenceInPercent === null ? 'N/A' : `${costPerUnitDifferenceInPercent.toFixed(2)} %`,
      };
    }),
  }));

  const filteredList = applySortFilter(upgradedList, getComparator(order, orderBy), filterName, (item) =>
    getFilteredItem(item)
  );
  const isItemNotFound = filteredList.length === 0;

  const seeCost = useAccess('tobaccoInventory.views.cost');

  return (
    <Card>
      <TableToolbar filterName={filterName} onFilterName={handleFilterByName} submitOnEnter />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              isLoading={loading}
              size={SIZE}
              order={order}
              orderBy={orderBy}
              headLabel={getTableHead(t)}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />

            {!loading && (
              <TableBody>
                {filteredList.map((row) => (
                  <ConveyorTransactionsRow
                    key={row.tobaccoContainer}
                    row={row}
                    loading={loading}
                    columnCount={getTableHead(t).length}
                    seeCost={seeCost}
                    handleOpenCorrectionModal={handleOpenCorrectionModal}
                    handleOpenCorrectionHistoryInfo={handleOpenCorrectionHistoryInfo}
                    corrections={corrections}
                  />
                ))}
              </TableBody>
            )}

            {isItemNotFound || loading ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                    <SearchNotFound isLoading={loading} searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableFooter />
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

ConveyorTransactionsTable.propTypes = {
  list: PropTypes.array,
  corrections: PropTypes.array,
  handleOpenCorrectionModal: PropTypes.func,
  handleOpenCorrectionHistoryInfo: PropTypes.func,
  loading: PropTypes.bool,
};
