import { groupBy, isArray, isBoolean, keyBy } from 'lodash';
import { PropTypes } from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import factoryOptionsApi from '../api/factoryOptionsApi';
import roomsApi from '../api/roomsApi';
import { useAuth } from './AuthProvider';

//---------------------------------------------------

const FactoryOptionsContext = createContext(null);

const FactoryOptionsProvider = ({ children }) => {
  const { isAuthentificated } = useAuth();

  const [factoryOptions, setFactoryOptions] = useState({});
  const [rooms, setFactoryRooms] = useState([]);

  const getOptions = useCallback(async () => {
    const [roomsRes, optionsRes] = await Promise.all([roomsApi.getInventoryRooms(), factoryOptionsApi.get()]);

    if (optionsRes.status === 200 && isArray(optionsRes.data?.data)) {
      const optionsObject = groupBy(optionsRes.data.data, 'optionName');
      const factoryConfigObject = keyBy(optionsObject?.factoryConfig, 'name');

      setFactoryOptions({
        ...optionsObject,
        ...factoryConfigObject,
      });
    }

    if (roomsRes.status === 200 && isArray(roomsRes.data?.data)) {
      setFactoryRooms(roomsRes.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomsApi, factoryOptionsApi, setFactoryOptions, setFactoryRooms]);

  useEffect(() => {
    if (isAuthentificated) getOptions();
  }, [isAuthentificated, getOptions]);

  const value = useMemo(
    () => ({
      factoryOptions,
      rooms,
      refetch: getOptions,
    }),
    [factoryOptions, getOptions, rooms]
  );

  return <FactoryOptionsContext.Provider value={value}>{children}</FactoryOptionsContext.Provider>;
};

FactoryOptionsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const useFactoryOptions = () => useContext(FactoryOptionsContext);

export const useFactoryConfigValue = (name) => {
  const { factoryOptions } = useFactoryOptions() || {};
  const configItem = factoryOptions ? factoryOptions[name] : null;

  return isBoolean(configItem?.value) ? configItem.value : configItem?.value || null;
};

export default FactoryOptionsProvider;
